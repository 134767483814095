<template>
  <div>
    <div class="bg-cl-primary py35 pl20">
      <div class="container">
        <breadcrumbs :routes="[{name: 'Homepage', route_link: '/'}]" :active-route="$props.title" />
        <h2 class="fs-big title">
          {{ $props.title }}
        </h2>
      </div>
    </div>

    <div class="container pt15 pb70">
      <div class="row pl20 pt0">
        <div class="col-sm-3 menu">
          <nav class="static-menu serif h4 mb35">
            <ul class="m0 p0">
              <li class="mb10" v-for="navigationPage in navigation" :key="navigationPage.id">
                <router-link :to="navigationPage.link" @click.native="setContent(navigationPage.component)" class="cl-accent relative">
                  {{ navigationPage.title }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="static-content h4 lh35 col-sm-9">
          <component :is="activeComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../components/Breadcrumbs'
// import StaticExample from '@/components/blocks/Static/Example'
import Faq from '../components/blocks/Static/Faq'
import About from '../components/blocks/Static/About'
import Technology from '../components/blocks/Static/Technology'
import Privacy from '../components/blocks/Static/Privacy'
// import Contact from '../components/blocks/Static/Contact'

export default {
  components: {
    Breadcrumbs
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || this.$props.title,
      meta: this.$route.meta.description ? [{ vmid: 'description', description: this.$route.meta.description }] : []
    }
  },
  created () {
    this.setComponentByRoutePath(this.$route.path)
  },
  props: {
    title: {
      type: String,
      required: true
    },
    page: {
      type: String,
      required: true
    }
  },
  methods: {
    setContent (component) {
      this.activeComponent = component
    },
    setComponentByRoutePath (routePath) {
      this.setContent(this.navigation.find(nav => nav.link === routePath) ? this.navigation.find(nav => nav.link === routePath).component : null)
    }
  },
  watch: {
    $route (val) {
      this.setComponentByRoutePath(val.path)
    }
  },
  data () {
    return {
      navigation: [
        // { title: 'Customer service', link: '/customer-service', component: StaticExample },
        // { title: 'Store locator', link: '/store-locator', component: StaticExample },
        // { title: 'Delivery', link: '/delivery', component: StaticExample },
        // { title: 'Return policy', link: '/returns', component: StaticExample },
        { title: 'Privacy policy', link: '/privacy', component: Privacy },
        // { title: 'Size guide', link: '/size-guide', component: StaticExample },
        // { title: 'Contact us', link: '/contact', component: Contact },
        { title: 'Faq', link: '/faq', component: Faq },
        { title: 'Our Mission', link: '/about', component: About },
        { title: 'Technology', link: '/technology', component: Technology }
      ],
      activeComponent: null
    }
  }
}
</script>

<style lang="scss" scoped>

$border-primary: color(primary, $colors-border);
.menu {
  display: none;
  @include respond-to(tablet) {
    display: block;
  }
}
.title {
  text-align: center;
  margin-left: -28px;
  margin-bottom: -20px;
  @include respond-to(tablet) {
    text-align: left;
    margin-left: 0;
    margin-bottom: unset;
  }
}
.static-menu {
  ul {
    list-style: none;
  }

  a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $border-primary;
  }

  a:hover::after,
  .router-link-active::after {
    opacity: 0;
  }
}

.static-content {
  *:first-of-type {
    margin-top: 0;
  }
}
</style>

<style lang="scss">
.static-content {
  h3 {
    margin-top: 40px;
    &:first-of-type {
      margin-top: 0;
    }
    margin-bottom: 25px;
    @include smaller-than(tablet) {
      margin-top: 35px;
      margin-bottom: 10px;
      margin-left: -28px;
      text-align: center;
    }
  }
  .faq-question {
    font-weight: bold;
  }
}
</style>
